import React from "react";
import * as icons from 'react-feather';

const Icon = ({icon, stroke, size, classes}) => {
    const FeatherIcon = icons[icon];

    if (!FeatherIcon) {
        return null;
    }
    
    return (
        <FeatherIcon size={size ? size : 14} className={classes} />
    )
}

export default Icon;