import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import MenuItem from "./MenuItem";
import Icon from "./elements/Icon";
import Logo from "../assets/svgs/logo.svg";

const R = require('ramda');

export default function Footer() {

    function renderMenu(menu, classNames) {
        {
            if (menu.menuItems.nodes) {
                return Object.values(menu.menuItems.nodes).map((menuItem) => {
                    return (
                        <li className="hover:underline pb-1">
                            <MenuItem
                            classNames={classNames}
                            key={menuItem.id}
                            menuItem={menuItem}
                            wordPressUrl={wordPressUrl}
                        />
                        </li>
                    )
                })
            }
        }
    }

    const footer = useStaticQuery(graphql`
    query FooterQuery {
      wp {
        generalSettings {
          url
        }
      }
 
     allWpMenu(filter: {name: {in: ["Footer menu rechts","Footer menu links", "Footer"]}}) {
    nodes {
      id
      name
      menuItems {
        nodes {
          id
          label
          url
          title
          target
        }
      }
    }
  }
 
     allWp {
        nodes {
          options {
            acf_footer {
              footerColumn1 {
                footer1Title
                footer1Omschrijving
              }
              footerColumn2 {
                footer2Title
              }
            }
          }
        }
      }
    }
  `);


    const footerMenus = R.path(['allWpMenu', 'nodes'])
    const wordPressUrl = footer.wp.generalSettings.url;

    const menus = footerMenus(footer);

    const footerMenu = (R.find(R.propEq('name', 'Footer'))(menus));
    const leftMenu = (R.find(R.propEq('name', 'Footer menu links'))(menus));
    const rightMenu = (R.find(R.propEq('name', 'Footer menu rechts'))(menus));

    return (
        <>
            <div className="bg-gray-100">
                <div
                    className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center">
                    <div className="grid lg:grid-cols-2 w-full">
                        <h2 className="font-bold tracking-tight text-gray-900 sm:text-4xl text-center lg:text-left">
                            <span className="block text-xl">Klaar voor je nieuwe levensstijl?</span>
                            <span className="block uppercase text-3xl lg:text-5xl">Let's get started!</span>
                        </h2>
                        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 grid-cols-2 justify-between lg:pl-14">
                            <a className="rounded-full w-20 shadow hover:shadow-xl h-20 bg-white flex content-center hover:bg-black hover:text-white"
                               href="https://api.whatsapp.com/send?phone=31618793697" target="_new" rel="noreferrer">
                                <svg className="m-auto block" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="2.5rem"
                                     width="2.5rem" xmlns="http://wnww.w3.org/2000/svg">
                                    <path
                                        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                                </svg>
                            </a>
                            <a className="rounded-full w-20 shadow hover:shadow-xl h-20 bg-white flex content-center hover:bg-black hover:text-white"
                               href="mailto:info@vitabelle.nl" target="_new" rel="noreferrer">
                                <Icon icon="Mail" size="40" classes="m-auto block"/>
                            </a>
                            <a className="rounded-full w-20 shadow hover:shadow-xl h-20 bg-white flex content-center hover:bg-black hover:text-white"
                               href="tel://31618793697" target="_new" rel="noreferrer">
                                <Icon icon="Phone" size="40" classes="m-auto block"/>
                            </a>
                            <a className="hidden lg:flex rounded-full w-20 shadow hover:shadow-xl h-20 bg-white content-center hover:bg-black hover:text-white"
                               href="https://www.instagram.com/vitabelle_coaching/" target="_new" rel="noreferrer">
                                <Icon icon="Instagram" size="40" classes="m-auto block"/>
                            </a>

                            <a className="hidden lg:flex rounded-full w-20 shadow hover:shadow-xl h-20 bg-white content-center hover:bg-black hover:text-white"
                               href="https://nl-nl.facebook.com/vitabellecoaching/" target="_new" rel="noreferrer">
                                <Icon icon="Facebook" size="40" classes="m-auto block"/>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            <footer className="py-10">
                <div
                    className="container px-6 mx-auto space-y-6 divide-y divide-gray-400 md:space-y-12 divide-opacity-50">
                    <div className="grid lg:grid-cols-12 lg:gap-24">
                        <div className="pb-6 col-span-full md:pb-0 lg:col-span-6 lg:mb-0 mb-10">
                            <div className="flex justify-center space-x-3 md:justify-start content-center h-full">
                                <div className="w-32 self-center">
                                    <Logo/>
                                </div>
                                <span className="self-center text-2xl font-semibold">Vita Belle Coaching <br/> <span
                                    className="text-sm">Voedingsadvies en coaching voor verantwoord afvallen.</span></span>
                            </div>
                        </div>
                        <div className="col-span-12 text-center md:text-left md:col-span-3 mb-10 lg:mb-0">
                            <p className="pb-1 text-lg font-medium">Vita Belle Coaching</p>
                            <ul>
                                {renderMenu(leftMenu, 'text-md')}
                            </ul>
                        </div>
                        {/*<div className="col-span-6 text-center md:text-left md:col-span-3">*/}
                        {/*    <p className="pb-1 text-lg font-medium">&nbsp;</p>*/}
                        {/*    <ul className="p-0 m-0">*/}
                        {/*        {renderMenu(rightMenu, 'text-md')}*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>
                    <div className="grid justify-center pt-6 lg:justify-between">
                        <div
                            className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
                            <span>©2021 All rights reserved</span>
                        </div>
                        <div className="flex justify-center pt-4 space-x-4 lg:pt-0 lg:col-end-13">
                            {
                                Object.values(footerMenu.menuItems.nodes).map((menuItem) => {
                                    return (
                                        <MenuItem
                                            classNames={"inline mx-4 text-sm hover:underline"}
                                            key={menuItem.id}
                                            menuItem={menuItem}
                                            wordPressUrl={wordPressUrl}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </footer>
            <div className="_1bpcM">
                <a href="https://api.whatsapp.com/send?phone=31618793697" target="_blank" rel="noreferrer">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em"
                         width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                    </svg>
                </a>
            </div>
        </>
    );
}
