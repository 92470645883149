import React from "react"
import Helmet from "react-helmet"

function Seo({ seo }) {
    if (seo) {
        return (
            <Helmet
                title={seo.title}
                titleTemplate={seo.title}
                meta={[
                    {
                        name: `description`,
                        content: seo.metaDesc,
                    },
                    {
                        property: `og:title`,
                        content: seo.title,
                    },
                    {
                        property: `og:description`,
                        content: seo.metaDesc,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                ]
                }
            />
        )
    }
}

export default Seo