import React, {useState} from "react";
import {useStaticQuery, graphql, Link} from "gatsby";
import {Menu as MenuIcon, X} from "react-feather";
import {Transition} from "@headlessui/react";
import MenuItem from "./MenuItem";
import Logo from "../assets/svgs/full-logo.svg";
import MobileLogo from "../assets/svgs/logo.svg";

import Icon from "./elements/Icon";

export default function Navbar({isSticky}) {
    const menu = useStaticQuery(graphql`
    query MenuQuery {
      wp {
        generalSettings {
          url
        }
      }
      wpMenu(name: { eq: "Top menu" }) {
        id
        name
        menuItems {
          nodes {
            id
            label
            url
            title
            target
          }
        }
      }
    }
  `);

    const [isOpen, setIsOpen] = useState(false);
    const handleOnClick = () => {
        setIsOpen(!isOpen);
    };

    if (!menu.wpMenu.name) {
        return null;
    }

    const menuItems = menu.wpMenu.menuItems.nodes;
    const wordPressUrl = menu.wp.generalSettings.url;
    let i = 0;

    return (
        <nav className={`h-full bg-white navbar z-10 ${isSticky ? 'is-sticky shadow' : 'non-sticky' }`}>
            <div className="container mx-auto font-medium h-full">
                <div className="flex items-center justify-between h-full">
                    <div className="inset-y-0 left-0 lg:hidden top-0 w-full items-center mobile-button flex shadow z-10 bg-white">
                       <div className="w-1/3 flex">
                           <button
                               onClick={() => handleOnClick()}
                               className="justify-center p-0 bg-white m-3 rounded-md text-black  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                               aria-expanded="false"
                           >
                               <span className="sr-only">Open main menu</span>
                               <MenuIcon className={`${isOpen ? "hidden" : "block"}`}/>
                               <X className={`${isOpen ? "block" : "hidden"}`}/>
                           </button>
                       </div>
                        <Link to={"/"} className="block w-1/3 flex mobile-logo items-center justify-center">
                            <MobileLogo />
                        </Link>
                        <div className="flex justify-center w-1/3">
                            <a href="mailto:info@vitabelle.nl" target="_new"  rel="noreferrer">
                                <Icon icon="Mail" size="15" classes="mx-1 hover:text-primary" />
                            </a>
                            <a href="tel://31618793697" target="_new"  rel="noreferrer">
                                <Icon icon="Phone" size="15" classes="mx-1 hover:text-primary" />
                            </a>
                            <a href="https://api.whatsapp.com/send?phone=31618793697" target="_new"  rel="noreferrer">
                                <Icon icon="Smartphone" size="15" classes="mx-1 hover:text-primary" />
                            </a>
                            <a href="https://www.instagram.com/vitabelle_coaching/" target="_new"  rel="noreferrer">
                                <Icon icon="Instagram" size="15" classes="mx-1 hover:text-primary" />
                            </a>
                            <a href="https://nl-nl.facebook.com/vitabellecoaching/" target="_new"  rel="noreferrer">
                                <Icon icon="Facebook" size="15" classes="mx-1 hover:text-primary" />
                            </a>
                        </div>
                    </div>
                    <div className="flex-1 flex lg:items-stretch lg:justify-start h-full">
                        <div className="hidden lg:block h-full w-full">
                            <div className="flex space-x-4 items-center justify-center h-full w-full">
                                {Object.values(menuItems).map((menuItem, s) => {
                                    i++;
                                    if (i === 4) {
                                        return (
                                            <React.Fragment key={`nav-${s}`}>
                                                <div className={`${isSticky ? 'none' : ''} logo pt-5 pb-3`}>
                                                    <Link to={"/"}>
                                                        <Logo/>
                                                    </Link>
                                                </div>
                                                    <MenuItem
                                                    classNames={"block text-lg md: px-5 hover:text-dark"}
                                                    key={menuItem.id}
                                                    menuItem={menuItem}
                                                    wordPressUrl={wordPressUrl}
                                                />
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <MenuItem
                                            key={`nav-${s}`}
                                            classNames={"block text-lg md: px-5 hover:text-dark"}
                                            menuItem={menuItem}
                                            wordPressUrl={wordPressUrl}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:hidden absolute w-full h-full">
                <Transition show={isOpen}
                            enter="transition ease duration-700 transform"
                            enterFrom="opacity-0 -translate-y-full"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease duration-1000 transform"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 -translate-y-full"
                >
                    <div className="px-2 pt-2 pb-3 block w-full bg-white text-center  block bg-white z-20 relative absolute">
                        {Object.values(menuItems).map((menuItem) => {
                            return (
                                <MenuItem
                                    key={menuItem.id}
                                    menuItem={menuItem}
                                    wordPressUrl={wordPressUrl}
                                    classNames="block mb-5 font-bold"
                                />
                            );
                        })}
                    </div>
                </Transition>
            </div>
        </nav>
    );
}
