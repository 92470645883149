export const CreateLocalLink = (menuItem, wordPressUrl) => {
  const { url } = menuItem;

  if (url === "#") {
    return null;
  }

  let newUri = url.replace(wordPressUrl, "");

  return newUri;
};
