import React from "react";
import { CreateLocalLink } from "../utils";
import { Link } from "gatsby";

const MenuItem = ({ menuItem, wordPressUrl, classNames}) => {
  const url = CreateLocalLink(menuItem, wordPressUrl);

  return (
    <Link className={classNames} to={url} replace>{menuItem.label}</Link>
  );
};

export default MenuItem;