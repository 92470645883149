import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { StickyContainer, Sticky } from '@jonny1994/react-sticky';

const Layout = ({children}) => (
    <StickyContainer>
        <div className="min-h-screen flex flex-col">
            <div className="flex-grow bg-white">
                <Sticky topOffset={100}>
                    {
                        ({
                             style,
                             isSticky,
                         }) =>
                            (
                                <>
                                    <header className={`z-10 relative`} style={style}>
                                        <Navbar isSticky={isSticky}/>
                                    </header>
                                        <main className={`${isSticky ? 'padding-container-top' : ''}`}>{children}</main>
                                </>
                        )
                    }
                </Sticky>
            </div>
             <Footer />
        </div>
    </StickyContainer>
);

export default Layout;
